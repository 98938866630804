






































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { MessageBoxInputData } from "element-ui/types/message-box";
import PaymentAuditDialog from "./components/PaymentAuditDialog.vue";
import DeliverDialog from "./components/DeliverDialog.vue";

@Component({
  components: { PaymentAuditDialog, DeliverDialog }
})
export default class MallOrderList extends Vue {
  pagerHeader: any = {
    title: "商城订单列表",
    desc: "商城订单列表",
    breadcrumb: ["订单管理", "商城订单列表"]
  };
  loading: boolean = false;
  filter: any = {
    agentId: null,
    status: [],
    paymentStatus: null,
    startDate: "",
    endDate: "",
    keywords: "",
    page: 1,
    size: 10
  };
  dateRange: any = [];
  tableData: any = {
    items: [],
    totalCount: 0,
    totalAmount: 0
  };
  merchantItems: any[] = [];
  merchantSearchLoading: boolean = false;
      //日期改变   
  timeVoi(value:Date[]){
    if (value != null && value.length == 2) {
        this.filter.startDate=value[0].Format("yyyy-MM-dd hh:mm:ss")
        if(value[1].Format('hh')=="00"&&value[1].Format('mm')=="00"&&value[1].Format('ss')=="00"){
            this.filter.endDate = value[1].Format("yyyy-MM-dd 23:59:59");
            this.dateRange=[this.filter.startDate=value[0].Format("yyyy-MM-dd hh:mm:ss"),this.filter.endDate = value[1].Format("yyyy-MM-dd 23:59:59")]
        }else{
            this.filter.endDate = value[1].Format("yyyy-MM-dd hh:mm:ss")
            this.dateRange=[this.filter.startDate=value[0].Format("yyyy-MM-dd hh:mm:ss"),this.filter.endDate = value[1].Format("yyyy-MM-dd hh:mm:ss")]
        }
      this.readerPage()
    } else {
      this.filter.startDate = "";
      this.filter.endDate = "";
      this.readerPage()
    } 
  }
  // 分页开始
  handleSizeChange(val: number) {
    this.filter.size = val;
    this.readerPage();
  }
  handleCurrentChange(val: number) {
    this.filter.page = val;
    this.readerPage();
  }
  async readerPage() {
    this.loading = true;
    try {
      let res = await this.$ajax.post(
        "/api/services/app/ShoppingOrder/QueryPagedList",
        {
            agentId: this.filter.agentId===""?null:this.filter.agentId,
            status:  this.filter.status,
            paymentStatus: this.filter.paymentStatus===""?null:this.filter.paymentStatus,
            startDate: this.filter.startDate,
            endDate: this.filter.endDate,
            keywords: this.filter.keywords,
            page: this.filter.page,
            size: this.filter.size
        }
      );

      if (res.data.success) {
        this.tableData = res.data.result;
      }
    } catch (error) {
      console.log(error);
    }
    this.loading = false;
  }

  search() {
    this.filter.page = 1;
    // this.filter.keywords = this.keywords;
    this.readerPage();
  }
  activated() {
    this.readerPage();
  }
  created() {
    this.filter.size = this.systemSettings.pageSize;
    this.readerPage();
    this.readerAgentOptions("");
    if(this.$route.query.status||this.$route.query.status==='0'){
        this.filter.status.push(Number(this.$route.query.status))
    }
    
  }
  get statusOptions() {
    return this.getEnum("ShoppingOrderStatus");
  }
  get paymentStatusOptions() {
    return this.getEnum("OrderPaymentStatus");
  }
  get paymentTypeOptions() {
    return [
      {
        name: "微信",
        value: 8
      },
      {
        name: "支付宝",
        value: 16
      }
    ];
  }
  async deleteOrder(id:string){
    //   
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then( () => {
            try {
                this.$ajax.delete(
                `/api/services/app/ShoppingOrder/Delete`,
                {
                params: {
                        id
                    }
                }
            ).then((res)=>{
                if (res.data.success) {
                    this.$message.success("删除成功");
                    this.readerPage();
                }else{
                    this.$message.success("删除失败");
                }
            })
            } catch (error) {
                console.log(error)
            }
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
  }
  async cancelOrder(id: string) {
    var dialogResult = (await this.$prompt(
      "请输入取消原因",
      "提示"
    )) as MessageBoxInputData;
    if (dialogResult.action == "confirm") {
      if (!dialogResult.value) return this.$notify.error("请输入取消原因");
      let loading = this.$loading({
        text: "取消中..."
      });
      try {
        if (dialogResult && dialogResult.action == "confirm") {
          var res = await this.$ajax.post(
            `/api/services/app/ShoppingOrder/Cancel`,
            {
              id: id,
              reason: dialogResult.value
            }
          );
          if (res.data.success) {
            this.$message.success("取消成功");
            this.readerPage();
          }
        }
      } catch (error) {}
      loading.close();
    }
  }
  agentItems: any[] = [];
  agentSearchLoading: boolean = false;
  async readerAgentOptions(query: string) {
    this.agentSearchLoading = true;
    try {
      var res = await this.$ajax.get("/api/services/app/Agent/GetPagedList", {
        params: {
          page: 1,
          size: 10,
          keywords: query
        }
      });
      this.agentItems = res.data.result.items;
    } catch (error) {}
    this.agentSearchLoading = false;
  }
  auditPayment(id: string) {
    (this.$refs.auditDialog as any).show(id);
  }
  orderDeliver(id: string) {
    (this.$refs.deliverDialog as any).show(id);
  }
  async completeOrder(id: string) {
    var result = await this.$confirm("确认帮助代理确认收货完成该订单吗");
    if (result == "confirm") {
      var loading = this.$loading({
        text: "请稍后..."
      });
      try {
        var res = await this.$ajax.post(
          "/api/services/app/ShoppingOrder/Complete",
          {
            id: id
          }
        );
        if (res.data.success) {
          this.$notify.success("订单确认完成成功");
          this.readerPage();
        }
      } catch (error) {}
      loading.close();
    }
  }
}
