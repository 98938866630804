
















import { Component, Vue, Watch } from "vue-property-decorator";

@Component
export default class DeliverDialog extends Vue {
  dialogFormVisible: boolean = false;
  formData: any = {
    logisticsCompanyName: "",
    logisticsNo: "",
    id: ""
  };
  loading: boolean = false;
  rules: any = {
    logisticsCompanyName: [
      { required: true, message: "请输入物流公司", trigger: "change" }
    ],
    logisticsNo: [
      { required: true, message: "请输入物流单号", trigger: "blur" }
    ]
  };
  show(id: string) {
    this.dialogFormVisible = true;
    this.formData = {
      logisticsCompanyName: "",
      logisticsNo: "",
      id
    };
  }
  submitDeliver() {
    (this.$refs["deliverForm"] as any).validate(async (valid: boolean) => {
      if (valid) {
        this.loading = true;
        try {
          var res = await this.$ajax.post(
            "/api/services/app/ShoppingOrder/Deliver",
            this.formData
          );
          if (res.data.success) {
            this.dialogFormVisible = false;
            this.$notify.success("发货成功");
            this.$emit("confirm");
          }
        } catch (error) {}
      }
      this.loading = false;
    });
  }
}
