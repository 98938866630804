
































import { Component, Vue, Watch } from "vue-property-decorator";

@Component
export default class PaymentAuditDialog extends Vue {
  dialogFormVisible: boolean = false;
  formData: any = {
    successed: true,
    paymentType: "",
    tradeNo: "",
    auditMessage: "",
    id: ""
  };
  loading: boolean = false;
  rules: any = {
    paymentType: [
      { required: true, message: "选择支付方式", trigger: "change" }
    ],
    tradeNo: [{ required: true, message: "请输入支付单号", trigger: "blur" }],
    auditMessage: [
      { required: true, message: "请输入审核消息", trigger: "blur" }
    ]
  };
  show(id: string) {
    this.dialogFormVisible = true;
    this.formData = {
      successed: true,
      paymentType: "",
      tradeNo: "",
      auditMessage: "",
      id
    };
  }
  get paymentOptions() {
    return [
      {
        name: "银行转账",
        value: 1
      },
      {
        name: "微信转账",
        value: 2
      },
      {
        name: "支付宝转账",
        value: 4
      }
    ];
  }
  submitAudit() {
    (this.$refs["auditForm"] as any).validate(async (valid: boolean) => {
      if (valid) {
        this.loading = true;
        try {
          var res = await this.$ajax.post(
            "/api/services/app/ShoppingOrder/PaymentAudit",
            this.formData
          );
          if (res.data.success) {
            this.dialogFormVisible = false;
            this.$notify.success("审核成功");
            this.$emit("confirm");
          }
        } catch (error) {}
      }
      this.loading = false;
    });
  }
}
